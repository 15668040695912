<template>
  <div class="jh-container">
    <div class="jh-ui-header">
      <h1>계약문의 관리</h1>
      <div class="is-right">
      </div>
    </div>

    <!-- 조회 -->
    <div class="jh-search-form">
      <table>
        <colgroup>
          <col width="30px">
          <col width="400px">
          <col width="70px">
          <col width="120px">
          <col width="70px">
          <col width="120px">
          <col>
        </colgroup>
        <tr>
          <th><label>제목</label></th>
          <td>
              <v-text-field class="jh-form" v-model="txtFldTitleV" @keyup.enter="clickGetList" placeholder="제목"></v-text-field>
          </td>
          <th><label>등록자</label></th>
          <td>
              <v-text-field class="jh-form" v-model="txtFldRegV" @keyup.enter="clickGetList" placeholder="등록자"></v-text-field>
          </td>
          <!-- 11.04 주석처리: 추후 필요시 다시 주석해제 -->
          <!-- <th><label>수정자</label></th>
          <td>
              <v-text-field class="jh-form" v-model="txtFldModV" @keyup.enter="clickGetList" placeholder="수정자"></v-text-field>
          </td> -->
          <td class="has-search">
              <v-btn class="jh-btn is-search" @click="clickGetList">조회</v-btn>
          </td>
        </tr>
      </table>
    </div>
    <!--조회-->

    <div class="jh-form-wrap">          
      <div class="jh-ui-header">
        <h2>계약문의 리스트</h2>
        <div class="is-right">
          <!-- <v-btn class="jh-btn is-border-blue" @click="clickNewWrite" v-if="this.mixin_set_btn(this.$options.name, 'btnNew')">새글 등록</v-btn> -->
        </div>
      </div>

      <data-tables
        ref="dataTable"
        dataTableClass="has-control"
        :data-table-options="dataTableOptions"
        @click:row="rowClick"
        @dblclick:row="dbClickListRow"
        :paginationOptions="paginationOptions"
        v-model="gridListItemSelected"
      >
        <template v-slot:item.BRD_TIT="{ item }" >
          <td>
            <div class="jh-align-middle" :style="(item.COMPLETE_YN == 'N')? 'font-weight: bold; color:red;' : ''">
              {{ item.BRD_TIT }}
              <i class="jh-icon-new is-ml-5" v-if="item.BRD_TIT_NEW == 'Y'"></i>
            </div>
          </td>
        </template>
      </data-tables>
    </div>

    <!-- 새글 등록 모달창 -->
    <!-- <v-dialog max-width="900" persistent v-if="dialogE050202P01" v-model="dialogE050202P01" :eager="true">
      <dialog-E050202P01
        headerTitle="새글 등록"
        :param="E050202P01Param"
        @hide="hideDialog('E050202P01')"
        @Qsave="clickGetList(), hideDialog('E050202P01')"
      >
      </dialog-E050202P01>
      <template v-slot:body></template>
    </v-dialog> -->

    <!-- 게시글 상세 모달창 -->
    <v-dialog max-width="900" persistent v-if="dialogE999995P02" v-model="dialogE999995P02" :eager="true">
      <dialog-E999995P02
        headerTitle="상세내용보기"
        :param="E999995P02Param"
        @hide="hideDialog('E999995P02')"
        @Qsave="clickGetList(), hideDialog('E999995P02')"
      >
      </dialog-E999995P02>
      <template v-slot:body></template>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/store/apiUtil";
import { mixin } from "@/mixin/mixin";
import DialogE999995P02 from "./E999995P02.vue";
import dataTables from '@/components/DataTables.vue';

export default {
  name: "MENU_E999995", //name은 'MENU_' + 파일명 조합
  components: {
    DialogE999995P02,
    dataTables,
  },
  mixins: [mixin],
  data() {
    return {
      nowDate: "",
      tempDate: "",
      brdId: "6",

      dialogE999995P02: false,
      E999995P02Param: {},

      // 조회 셀렉트박스 아이템 리스트
      selBoxBiz: [],

      // 조회 v-model
      selBoxBizV: {},
      txtFldTitleV: "",
      txtFldRegV: "",
      txtFldModV: "",
      BULTN_TO_DT: "",

      //리스트
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      totalVisible: 10,

      gridListItemSelected: [],
      gridListDataSelect: [],
      gridListDataText: [],
      gridListDataHeaders: [
        {
          text: "No",
          align: "center",
          value: "ROWNUM",
          width: "50px",
          sortable: true,
        },
      //   {
      //     text: "유형",
      //     value: "BRD_QUICK_TY_NM",
      //     align: "center",
      //     width: "80px",
      //     sortable: true,
      //   },
        {
          text: "제목",
          value: "BRD_TIT",
          align: "center",
          sortable: true,
        },
        {
          text: "접수유무",
          value: "COMPLETE_YN",
          align: "center",
          width: "5%",
          sortable: true,
        },        
        {
          text: "첨부파일",
          value: "FILE_CNT",
          align: "center",
          width: "5%",
          sortable: true,
        },
        {
          text: "등록자",
          value: "firstID",
          align: "center",
          width: "10%",
          sortable: true,
        },
        {
          text: "등록일시",
          value: "FST_BRD_DT",
          align: "center",
          width: "11%",
          sortable: true,
        }, //ASP_CUST_KEY
        {
          text: "수정자",
          value: "lastID",
          align: "center",
          width: "8%",
          sortable: true,
        },
        {
          text: "수정일시",
          value: "LAST_BRD_DT",
          align: "center",
          width: "10%",
          sortable: true,
        },
        {
          text: "조회수",
          value: "SELECT_NO",
          align: "center",
          width: "5%",
          sortable: true,
        },
        //  {
        //    text: "게시기간",
        //    value: "tempDate",
        //    align: "center",
        //    width: "13%",
        //    sortable: true,
        //  },
      ],

      dataTableOptions: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '600px',
        items: [],
        itemKey: 'index',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        singleSelect: true,
        disableSort: true, // 전체 정렬 안되게 한다.
        
        // 화면 오픈 시 리스트 조회
        load: this.getGridList
      },

      paginationOptions: {
        totalVisible: 10
      },

      alertMsg: {
        selBoxBizChk : "회사구분 은(는) 필수입력입니다.",
        noCntData    : "조회된 데이터가 없습니다.",
        newWriteChk  : "조회 후 작성 가능합니다.",
        delChk       : "현 게시물을 삭제 할 경우 댓글 및 댓글까 전부 조회되지 않습니다. 계속 진행하시겠습니까?",
        selBoxIdChk  : "게시판명 은(는) 필수입력입니다.",
        responseError: "시스템에 오류가 발생하였습니다.",
      },

      requestData: {}
    };
  },
  
  // 화면 오픈시 리스트 조회
  created(){
    this.setDataTableParams();

    this.dataTableOptions.headers = this.gridListDataHeaders;
    this.dataTableOptions.items = this.gridListDataText;
  },

  methods: {
    //회사구분 셀렉트박스 선택
    // clickSelBoxBizItem() {
    //   this.inquiryBool = false;
    // },

    //게시판 그리드 데이터 조회
    clickGetList() {
      //this.inquiryBool = true;

      // if (this.selBoxBizV == "" || this.selBoxBizV == null) {
      //   this.common_alert(this.alertMsg.selBoxBizChk, "error");
      //   return;
      // }
      // this.getGridList();
      this.setDataTableParams();

      if (this.dataTableOptions.page > 1) this.dataTableOptions.page = 1;
        else this.dataTableInstance.loadData();
    },

    setDataTableParams: function() {
      this.requestData = {
        headers: {},
        sendData: {},
      };

      this.requestData.headers["URL"] = "/api/board/list-paging";
      this.requestData.headers["SERVICE"] = "board";
      this.requestData.headers["METHOD"] = "list-paging";
      this.requestData.headers["ASYNC"] = false;
      this.requestData.headers["TYPE"] = "BIZ_SERVICE";

      this.requestData.sendData["BRD_ID"] = "2";
      this.requestData.sendData["MODE_ID"] = "VIEW";
      this.requestData.sendData["ASP_NEWCUST_KEY"] = "001";
      this.requestData.sendData["BRD_TIT"] = this.restoreXSS_CKeditorDec(this.txtFldTitleV);
      this.requestData.sendData["FST_USER_NM"] = this.txtFldRegV;
      this.requestData.sendData["LAST_USER_NM"] = this.txtFldModV;
      // this.requestData.sendData["BULTN_FR_DT"] = "";
      // this.requestData.sendData["BULTN_TO_DT"] = ""; // 현재 날짜가 지난 게시글은 보여주지 않는다.
      this.requestData.sendData["NOTICE_STATUS"] = "";

      //부서정보 처리
      let chkGroup = this.$store.getters["authStore/GE_AUTH_LOGIN_DATA"].AUTHOR_GROUP_NM_LIST;
      if(chkGroup.indexOf("시스템") == -1){
        this.requestData.sendData["USER_ATTR_A"] = this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_A;
      }
    },

    // 그리드 데이터 호출
    async getGridList(loadOptions) {
      this.requestData.headers["ROW_CNT"] = loadOptions.itemsPerPage;
      this.requestData.headers["PAGES_CNT"] = loadOptions.page;

      return this.common_postCall(this.requestData).then(response => {
        let resHeader = response.HEADER;

        let resData = [];
        let checkObj = {};
        let fixedCount = 0;
        if(Array.isArray(response.FIXED_LIST)) {
          response.FIXED_LIST.forEach(obj => {
            obj['COMPLETE_YN'] = 'N';
            checkObj[obj.BRD_NO] = 1;
          });
          resData = response.FIXED_LIST;
          fixedCount = response.FIXED_LIST.length;
        }
        if(typeof response.DATA == 'object'){
          resData = resData.concat(response.DATA.filter(obj => {
            return typeof checkObj[obj.BRD_NO] == 'undefined'
          }));
        }

        // 데이터 조회 오류 시 alert
        if (resHeader.ERROR_FLAG == true) {
          this.common_alert(this.alertMsg.responseError, "error");
          return false;
        }
        let idx = 1;
        for (let i in resData) {
          let id = resData[i].BRD_NO;
            resData[i]["id"] = id;
            resData[i]["index"] = idx++;
            resData[i]["FST_BRD_DT"] = this.parseDateTime(resData[i]["FST_BRD_DT"]);
            resData[i]["LAST_BRD_DT"] = this.parseDateTime(resData[i]["LAST_BRD_DT"]);

            // 등록자: 최초 등록자의 이름(ID)
            resData[i]["firstID"] = resData[i]["FST_USER_NM"] + "(" + resData[i]["FST_USER_ID"] + ")"

            // 수정자: 마지막 등록자의 이름(ID)
            resData[i]["lastID"] = resData[i]["LAST_USER_NM"] + "(" + resData[i]["LAST_USER_ID"] + ")"
            
            // 게시기간
            resData[i]["tempDate"] = resData[i]["BULTN_FR_DT"] + " ~ " + resData[i]["BULTN_TO_DT"]

            resData[i]["BRD_TIT"] = this.restoreXSS_CKeditor(resData[i]["BRD_TIT"]);
        }
        return {
          data: resData,
          totalCount: parseInt(resHeader.TOT_COUNT) + fixedCount
        }
      });
    },

    clickNewWrite() {
      // if(this.gridListDataText.length == 0 || this.selBoxBizV == '') {
      // if (this.selBoxBizV == "") {
      //   this.common_alert(this.alertMsg.newWriteChk, "error");
      //   return;
      // }

      // if (!this.inquiryBool) {
      //   this.common_alert(this.alertMsg.newWriteChk, "error");
      //   return;
      // }

      // this.E050202P01Param = {
      //   flag: "new",
      //   userId: this.$store.getters["userStore/GE_USER_ID"],
      //   //bizCd: this.selBoxBizV.CD,
      //   //bizNm: this.selBoxBizV.CD_NM,
      //   item: this.gridListDataText[0],
      //   BRD_ID: "6",
      //   // userId   : this.$store.getters['userStore/GE_USER_ID'],
      //   // bizCd    : this.selBoxBizV,
      //   // listData : this.gridListDataText,
      //};

      //this.showDialog("E050202P01");
      //this.E050202P01Param = {};
    },

    //userTable row 클릭이벤트
    dbClickListRow(e, { item }) {
      // if (this.gridListDataText.length == 0 || this.selBoxBizV == "") {
      //   this.common_alert(this.alertMsg.newWriteChk, "error");
      //   return;
      // }

      this.E999995P02Param = {
        userId: this.$store.getters["userStore/GE_USER_ID"],
        //bizCd: this.selBoxBizV.CD,
        //bizNm: this.selBoxBizV.CD_NM,
        listData: this.gridListDataText,
        item: item,
      };
      this.showDialog("E999995P02");
    },

    showDialog(menu) {
      console.log("showDialog");
      this[`dialog${menu}`] = true;
    },
    hideDialog(menu) {
      console.log("hideDialog");
      this[`dialog${menu}`] = false;
      this.dataTableInstance.loadData();
    },

    parseDateTime(data) {
      let year = [data.substr(0, 4), data.substr(4, 2), data.substr(6, 2)].join(
        "/"
      );
      let hour = [
        data.substr(8, 2),
        data.substr(10, 2),
        data.substr(12, 2),
      ].join(":");

      return year + " " + hour;
    },

    getBrdId() {
      let menuList = {};
      menuList = this.$store.getters["userStore/GE_USER_MENU"];
      // console.log("here!!@@@", menuList);

      // === 메뉴정보 배열 구조 파싱 ===
      // for(let i in menuList) {
      //   if(menuList[i].C_NODE_NO === "E050202") {
      //     let tempUrl = menuList[i].NODE_URL;
      //     let idIndex = tempUrl.indexOf("BRD_ID=");
      //     return tempUrl.substring(idIndex+7);
      //   }
      // }

      for (let i in menuList) {
        //게시판 대메뉴
        if (menuList[i].C_NODE_NO === "E0502") {
          for (let a in menuList[i].child) {
            //게시판 중메뉴(페이지)
            if (menuList[i].child[a].C_NODE_NO === "E999995") {
              let tempUrl = menuList[i].child[a].NODE_URL_MENUID;
              let idIndex = tempUrl.indexOf("BRD_ID=");

              return tempUrl.substring(idIndex + 7);
            }
          }
        }
      }
    },

    rowClick: function (item, row) {
      row.select(true);
    },
  },

  mounted() {
    this.selBoxBiz = this.$store.getters["userStore/GE_USER_COMPANY"];
    this.brdId = this.getBrdId();

    if (this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.vue == "E999995") {
      if (this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.CHK == 1) {
        let CUST = {
          CD: this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.CHK_CD,
          CD_NM: this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.CHK_NM,
        };
        this.selBoxBizV = CUST;
        this.clickGetList();

        let initCHK = { CHK: 0, vue: "", CHK_CD: "", CHK_NM: "" };
        this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST = initCHK;
      }
    }
  },

  computed: {
    getCUST() {
      return this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST;
    },
    dataTableInstance: function() {
      return this.$refs.dataTable;
    },
  },

  watch: {
    getCUST(val, oldVal) {
      if (
        this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.vue == "E999995"
      ) {
        if (this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.CHK == 1) {
          let CUST = {
            CD: this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.CHK_CD,
            CD_NM:
              this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST.CHK_NM,
          };
          this.selBoxBizV = CUST;
          this.clickGetList();

          let initCHK = { CHK: 0, vue: "", CHK_CD: "", CHK_NM: "" };
          this.$store.getters["userStore/GE_USER_ROLE"].CHK_CUST = initCHK;
        }
      }
    },
  },
};
</script>

<style>
  .v-dialog:not(.v-dialog--fullscreen){max-height: fit-content;}
</style>
  